<template>
    <section class="zorro-doggos">
        
        <div class="zorro-carrossel">
            <div class="container pd-mobile pt-10">
                <h3 class="zorro-carrossel__title">Make their world full of cuddling joy again!</h3>
                <div class="toltip-mobile">
                    <p class="zorro-carrossel__description">The Cuddle collection consists of 3,000 cute NFTs living on the Ethereum blockchain.</p>
                    <div class="item-toltip">
                        <img src="@/assets/images/burst.png" >
                        <span>8 breeds available!</span>
                    </div>
                </div>  
            </div>
            <CarrosselDogs/>
            <div class="container pd-mobile">
                <div class="animation-numbers">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="animation-numbers__item">
                                <p class="item-number"><span class="number">74.1</span>%</p>
                                <h5 class="item-title">standard</h5>
                                <p class="item-description">stereotypical countrymen</p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="animation-numbers__item">
                                <p class="item-number"><span class="number">9.6</span>%</p>
                                <h5 class="item-title">rare</h5>
                                <p class="item-description">pride-of-the-nation professionals</p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="animation-numbers__item">
                                <p class="item-number"><span class="number">3.2</span>%</p>
                                <h5 class="item-title">rare +</h5>
                                <p class="item-description">coolest hotshots from all over</p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="animation-numbers__item">
                                <p class="item-number"><span class="number">1.6</span>%</p>
                                <h5 class="item-title">all star</h5>
                                <p class="item-description">famous national celebs</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import CarrosselDogs from "@/components/NFT/collection1/CarrosselDogs.vue";
// import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
    name: 'ZorroDoggos',
    components: {
        CarrosselDogs,
        // LottieAnimation
    }
}


  


</script>

<style lang="less">

@black: #2E3240;
@pink: #D551AD;
@bg: #ffe2d1;
@green: #97D26F;

.zorro-doggos {
    position: relative;
    background: @green;
    padding: 40px 0 60px;
    .toltip-mobile {
        .item-toltip {
            display: none;
        }
    }
    .first-element {
        margin-top: 120px;
    }
    &__imgs {
        position: relative;
        .zorro {
            position: relative;
            right: -80px;
        }
        .dog1 {
            position: absolute;
            left: 0;
            bottom: 300px;
            width: 120px;
        }
        .dog2 {
            position: absolute;
            width: 60px;
            left: -60px;
            bottom: 150px;
        }
        .dog3 {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 90px;
        }
    }
    &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        background: url('~@/assets/images/grass-curve-bottom.svg?raw');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        padding-top: 4vw;
        background-position-x: center;
        background-position-y: bottom;
        z-index: 2;
    }
    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        background: @bg;
        width: 100%;
        padding-top: 3vw;
        z-index: 1;
    }
    &__title {
        font-size: 40px;
        line-height: 65px;
        margin-bottom: 20px;
        color: #fff;
    }
    &__description {
        font-family: 'Filson Pro';
        font-size: 25px;
        line-height: 45px;
        color: @black;
        width: 1000px;
        max-width: 100%;
        margin-bottom: 70px;
    }
    /*
        This classes are for the directive. 
        For each element observed by our directive, the before-enter class is added.
    */
    .anm-rotate.before-enter {
        opacity: 0;
        transform: scale(0) rotateZ(-50deg);
        transition: all 1s ease-out;
    }
    /* 
        If the element intersects with the viewport, the before-enter class is added.
    */
    .anm-rotate.enter {
        opacity: 1;
        transform: scale(1) rotateZ(0deg);
    }
}

.zorro-carrossel {
    padding: 80px 0 150px;
    &__title {
        font-size: 40px;
        line-height: 65px;
        margin-bottom: 20px;
        color: #fff;
        text-align: center;
    }
    &__description {
        font-family: 'Filson Pro';
        font-size: 25px;
        line-height: 45px;
        color: @black;
        margin-bottom: 50px;
        text-align: center;
    }
    .animation-numbers {
        padding: 100px 0 60px;
        &__item {
            position: relative;
            text-align: center;
            &:before {
                content: '';
                position: absolute;
                width: 350px;
                height: 350px;
                left: 50%;
                top: 50%;
                right: 0;
                bottom: 0;
                margin-left: -175px;
                margin-top: -175px;
                border-radius: 50%;
                background: rgba(#90C86A , 0.8);
                z-index: 1;

                @media(min-width:1500px) {
                    width: 450px;
                    height: 450px;
                    margin-left: -225px;
                    margin-top: -225px;
                }
            }
            .item-number {
                position: relative;
                z-index: 2;
                font-size: 30px;
                line-height: 50px;
                color: @black;
            }
            .item-title {
                position: relative;
                z-index: 2;
                font-size: 40px;
                line-height: 65px;
                color: #fff;
                margin-bottom: 10px;
            }
            .item-description {
                position: relative;
                z-index: 2;
                font-family: 'Filson Pro';
                font-size: 22px;
                line-height: 34px;
                color: #000;
                max-width: 240px;
                margin: 0 auto;
            }
        }
    }
}

@media(max-width:768px) {
    .zorro-doggos {
        padding: 0px 0 60px;
        .container{
            margin: 0;
        }
         .toltip-mobile {
             display: flex;
             align-items: center;
             justify-content: space-between;
            .item-toltip {
                position: relative;
                width: 150px;
                height: 150px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                z-index: 3;
                text-align: center;
                transition: all ease-in-out 0.3s;
                img {
                    position: absolute;
                    z-index: 1;
                }
                span {
                    font-family: 'Filson Pro';
                    font-size: 18px;
                    line-height: 25px;
                    color: #fff;
                    position: relative;
                    z-index: 2;
                }
            }
        }
        &__title {
            font-size: 32px;
            line-height: 42px;
        }
        &__description {
            font-size: 22px;
            line-height: 36px;
        }
        &__imgs {
            overflow: hidden;
            .zorro {
                position: relative;
                right: -90px;
                width: 350px;
            }
            .dog1 {
                position: absolute;
                left: 45px;
                bottom: 185px;
                width: 70px;
            }
            .dog2 {
                position: absolute;
                width: 36px;
                left: 15px;
                bottom: 100px;
            }
            .dog3 {
                position: absolute;
                left: 35px;
                bottom: 0;
                width: 54px;
            }
        }
        .first-element {
            margin-top: 40px;
        }
        &-pg__text {
            font-size: 18px !important;
            line-height: 30px !important;
        }
    }
    .zorro-carrossel {
        padding: 20px 0 30px;
        &__title {
            font-size: 32px;
            line-height: 42px;
            text-align: left;
        }
        &__description {
            font-size: 18px;
            line-height: 30px;
            width: 200px;
            text-align: left;
            margin-bottom: 0;
        }
    }
    .main-carrossel-dogs {
        padding: 0px 0px 40px;
        position: relative;
    }
    .main-carrossel-dogs:before, .main-carrossel-dogs:after {
        display: none;
    }
    .zorro-carrossel .animation-numbers__item:before {
        width: 300px;
        height: 300px;
        margin-left: -150px;
        margin-top: -150px;
    }
    .zorro-carrossel .animation-numbers__item {
        width: 270px;
        max-width: 100%;
        margin: 0 auto;
        height: 270px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .zorro-carrossel .animation-numbers {
        padding: 30px 0 0px;
    }
    .zorro-carrossel .animation-numbers__item .item-title {
        font-size: 32px;
        line-height: 56px;
    }
    .zorro-carrossel .animation-numbers__item .item-description {
        font-size: 18px;
        line-height: 30px;
    }
}

@time: 0.5s;

.anima-svg {
    &.enter {
        #eni4fN2D0wX2_ts {
            animation: eni4fN2D0wX2_ts__ts 1580ms linear 1 normal forwards;
            animation-delay: @time;
        }
        @keyframes eni4fN2D0wX2_ts__ts {
            0% {
                transform: translate(323.999985px, 460.599991px) scale(0.006853, 0.071691);
            }
            1.898734% {
                transform: translate(323.999985px, 460.599991px) scale(1, 1);
            }
            100% {
                transform: translate(323.999985px, 460.599991px) scale(1, 1);
            }
        }
        #eni4fN2D0wX8_to {
            animation: eni4fN2D0wX8_to__to 1580ms linear 1 normal forwards;
            animation-delay: @time;
        }
        @keyframes eni4fN2D0wX8_to__to {
            0% {
                transform: translate(451.375364px, 308.474066px);
            }
            5.063291% {
                transform: translate(451.375364px, 308.474066px);
            }
            20.253165% {
                transform: translate(451.375364px, 58.474066px);
            }
            100% {
                transform: translate(451.375364px, 58.474066px);
            }
        }
        #eni4fN2D0wX9_to {
            animation: eni4fN2D0wX9_to__to 1580ms linear 1 normal forwards;
            animation-delay: @time;
        }
        @keyframes eni4fN2D0wX9_to__to {
            0% {
                transform: translate(304.7px, 190.495718px);
            }
            5.063291% {
                transform: translate(304.7px, 190.495718px);
            }
            24.050633% {
                transform: translate(344.7px, 51.495718px);
            }
            98.734177% {
                transform: translate(354.7px, 41.495718px);
            }
            100% {
                transform: translate(354.7px, 41.495718px);
            }
        }
        #eni4fN2D0wX9_tr {
            animation: eni4fN2D0wX9_tr__tr 1580ms linear 1 normal forwards;
            animation-delay: @time;
        }
        @keyframes eni4fN2D0wX9_tr__tr {
            0% {
                transform: rotate(28.20265deg);
            }
            5.063291% {
                transform: rotate(28.20265deg);
            }
            24.050633% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(0deg);
            }
        }
        #eni4fN2D0wX9 {
            animation: eni4fN2D0wX9_c_o 1580ms linear 1 normal forwards;
            animation-delay: @time;
        }
        @keyframes eni4fN2D0wX9_c_o {
            0% {
                opacity: 0;
            }
            1.265823% {
                opacity: 0;
                animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
            }
            5.696203% {
                opacity: 1;
            }
            100% {
                opacity: 1;
            }
        }
        #eni4fN2D0wX331_to {
            animation: eni4fN2D0wX331_to__to 1580ms linear 1 normal forwards;
            animation-delay: @time;
        }
        @keyframes eni4fN2D0wX331_to__to {
            0% {
                transform: translate(147.35px, 394.675px);
            }
            5.063291% {
                transform: translate(147.35px, 394.675px);
                animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
            }
            24.050633% {
                transform: translate(187.95px, 284.475px);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }
            98.734177% {
                transform: translate(195.35px, 263.675px);
            }
            100% {
                transform: translate(195.35px, 263.675px);
            }
        }
        #eni4fN2D0wX331 {
            animation: eni4fN2D0wX331_c_o 1580ms linear 1 normal forwards;
            animation-delay: @time;
        }
        @keyframes eni4fN2D0wX331_c_o {
            0% {
                opacity: 0;
            }
            1.265823% {
                opacity: 0;
                animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
            }
            5.696203% {
                opacity: 1;
            }
            100% {
                opacity: 1;
            }
        }
        #eni4fN2D0wX332_to {
            animation: eni4fN2D0wX332_to__to 1580ms linear 1 normal forwards;
            animation-delay: @time;
        }
        @keyframes eni4fN2D0wX332_to__to {
            0% {
                transform: translate(329.524948px, 373.549969px);
            }
            5.063291% {
                transform: translate(329.524948px, 373.549969px);
                animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
            }
            24.050633% {
                transform: translate(369.524948px, 263.549969px);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }
            98.734177% {
                transform: translate(377.524948px, 242.549969px);
            }
            100% {
                transform: translate(377.524948px, 242.549969px);
            }
        }
        #eni4fN2D0wX332 {
            animation: eni4fN2D0wX332_c_o 1580ms linear 1 normal forwards;
            animation-delay: @time;
        }
        @keyframes eni4fN2D0wX332_c_o {
            0% {
                opacity: 0;
            }
            1.265823% {
                opacity: 0;
                animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
            }
            5.696203% {
                opacity: 1;
            }
            100% {
                opacity: 1;
            }
        }
        #eni4fN2D0wX1761_to {
            animation: eni4fN2D0wX1761_to__to 1580ms linear 1 normal forwards;
            animation-delay: @time;
        }
        @keyframes eni4fN2D0wX1761_to__to {
            0% {
                transform: translate(627.306099px, 26.9306px);
            }
            24.050633% {
                transform: translate(627.306099px, 26.9306px);
            }
            98.734177% {
                transform: translate(627.306099px, 16.9306px);
            }
            100% {
                transform: translate(627.306099px, 16.9306px);
            }
        }
        #eni4fN2D0wX1761_tr {
            animation: eni4fN2D0wX1761_tr__tr 1580ms linear 1 normal forwards;
            animation-delay: @time;
        }
        @keyframes eni4fN2D0wX1761_tr__tr {
            0% {
                transform: rotate(0deg);
            }
            24.050633% {
                transform: rotate(0deg);
                animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
            }
            98.734177% {
                transform: rotate(268.11477deg);
            }
            100% {
                transform: rotate(268.11477deg);
            }
        }
        #eni4fN2D0wX1761 {
            animation: eni4fN2D0wX1761_c_o 1580ms linear 1 normal forwards;
            animation-delay: @time;
        }
        @keyframes eni4fN2D0wX1761_c_o {
            0% {
                opacity: 0;
            }
            20.886076% {
                opacity: 0;
            }
            24.050633% {
                opacity: 1;
            }
            100% {
                opacity: 1;
            }
        }
    }
}

</style>