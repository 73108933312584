<template>
  <div>
    <!-- <BaseContainer class="pt-24 pb-16 relative z-20">
      NftCollection1
    </BaseContainer> -->
    <Collection1Hero />
    <Breeds />
    <Nftnomics />
    <Footer class='mt-40' />
  </div>
</template>

<script>
import Collection1Hero from "@/components/NFT/collection1/Collection1Hero"
import Breeds from "@/components/NFT/collection1/Breeds"
import Footer from "@/components/common/Footer"
import Nftnomics from '@/components/NFT/collection1/Nftnomics'

export default {
  name: "NftCollection1",
  components: {
    Collection1Hero,
    Breeds,
    Nftnomics,
    Footer,
    
  },
  data() {
    return {}
  },
}
</script>
