// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/clouds.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "section.hero[data-v-55535190]{position:relative;padding-top:90px;background:#ffe2d1 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") repeat 0 0;-webkit-animation:animateClouds-data-v-55535190 28s linear 0s infinite normal none;animation:animateClouds-data-v-55535190 28s linear 0s infinite normal none;overflow:hidden;margin-bottom:-1px}.logo-title-text-wrap[data-v-55535190]{padding-top:0;padding-bottom:calc(100px + 40vw)}@media (min-width:768px){.logo-title-text-wrap[data-v-55535190]{padding-bottom:calc(200px + 5vw)}}@media (min-width:1024px){.logo-title-text-wrap[data-v-55535190]{padding-bottom:300px}}.grass-wrap[data-v-55535190]{position:relative}.grass-wrap .animals-bowl[data-v-55535190]{position:absolute;left:0;right:0;margin:0 auto;bottom:calc(-20px + 5vw);width:100vw}@media (min-width:768px){.grass-wrap .animals-bowl[data-v-55535190]{width:50%;bottom:calc(-40px + 5vw);margin-right:10px}}.grass-wrap .animals-bowl .animals[data-v-55535190]{width:100%;z-index:0}.grass-wrap .animals-bowl .dog-bowl[data-v-55535190]{position:absolute;width:50%;left:0;right:0;bottom:-32%;margin:0 auto;z-index:1000;transform:rotate(6deg)}.grass-wrap .grass-top[data-v-55535190]{position:absolute;bottom:-30px;left:-40px;width:300vw;z-index:30}@media (min-width:768px){.grass-wrap .grass-top[data-v-55535190]{width:130vw;left:0;bottom:30px}}@media (min-width:1024px){.grass-wrap .grass-top[data-v-55535190]{bottom:90px}}.grass-wrap .grass-color[data-v-55535190]{background-color:#97d26f;height:20px;width:100vw}@media (min-width:768px){.grass-wrap .grass-color[data-v-55535190]{height:60px}}@media (min-width:1024px){.grass-wrap .grass-color[data-v-55535190]{height:100px}}@-webkit-keyframes animateClouds-data-v-55535190{0%{background-position:0 0}to{background-position:-1450px 0}}@keyframes animateClouds-data-v-55535190{0%{background-position:0 0}to{background-position:-1450px 0}}", ""]);
// Exports
module.exports = exports;
