<template>
  <section class="hero cont" v-view="onAppearInViewport">
    <BaseContainer class="relative z-30 pb-80">
      <HeroIntro
        :title="hero.title"
        :subtitle="hero.subtitle"
        :cta="hero.cta"
      />
    </BaseContainer>

    <div class="grass-wrap">
      <BaseContainer class="relative">
        <div class="animals-bowl">
          <img class="dog-bowl" src="@/assets/cuddledogs.svg?raw" />
        </div>
      </BaseContainer>
      <GrassTop class="grass-top" />
      <div class="grass-color" />
    </div>
  </section>
</template>

<script>
import mixinInitLoader from "@/mixins/initLoader"
import HeroIntro from "@/components/common/HeroIntro"
import CuddleLogo from "@/assets/cuddle_logo_color.svg?inline"
import GrassTop from "@/assets/grass-top.svg?inline"
import ImageDogBowlPink from "@/assets/images/svg/raw/dog-bowl-pink.svg?raw"

export default {
  name: "Hero",
  mixins: [mixinInitLoader],
  components: {
    HeroIntro,
    CuddleLogo,
    GrassTop,
    ImageDogBowlPink,
  },
  data() {
    return {
      showZorro: false,
      hero: {
        title: "Cuddle Doggos",
        subtitle: "The first NFT collection created “for real”. Blends the fun of NFTS with saving real animals, helping to raise funds for charitable activities. Details about the collection will be announced soon. ",
        cta: {
          url: "https://opensea.io/collection/cuddlenft-heroes-of-ukraine",
          label: "Check them out",
        },
      },
    }
  },
  computed: {
    imgDogBowlPink() {
      return ImageDogBowlPink
    },
  },
  mounted() {},
  methods: {
    onAppearInViewport(e) {
      if (e.type == "enter") this.showZorro = true
      if (e.type == "exit") this.showZorro = false
    },
  },
}
</script>

<style lang="less" scoped>
@import "../../../themes/variables.less";

section.hero {
  position: relative;
  padding-top: 90px;
  background: theme("colors.cdlpeach.DEFAULT")
    url("~@/assets/images/clouds.png") repeat 0 0;
  animation: 28s linear 0s normal none infinite animateClouds;
  overflow: hidden;
  margin-bottom: -1px;
}

.grass-wrap {
  position: relative;

  .animals-bowl {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: calc(-20px + 5vw);
    // width: 375px;
    width: 100vw;
    // outline: 1px #ff0000 solid;

    @media (min-width: @bp-md) {
      width: 50%;
      bottom: calc(-40px + 5vw);
      margin-right: 10px;
    }

    .animals {
      width: 100%;
      z-index: 0;

      // @media (min-width: @bp-md) {
      //   width: 400px;
      //   margin-right: 60px;
      //   bottom: -10px;
      // }

      // @media (min-width: @bp-lg) {
      //   width: calc(500px + 10vw);
      //   bottom: calc(100px - 1vw);
      //   right: calc(-50px - 10vw);
      // }
    }

    .dog-bowl {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      z-index: 1000;

      // @media (min-width: @bp-md) {
      //   width: 180px;
      //   margin-right: 170px;
      //   bottom: -60px;
      // }

      // @media (min-width: @bp-lg) {
      //   width: 260px;
      //   bottom: -20px;
      //   margin-right: 130px;
      // }
    }
  }
  .grass-top {
    position: absolute;
    bottom: -30px;
    left: -40px;
    width: 300vw;
    z-index: 30;

    @media (min-width: @bp-md) {
      width: 130vw;
      left: 0;
      bottom: 30px;
    }

    @media (min-width: @bp-lg) {
      bottom: 90px;
    }
  }
  .grass-color {
    background-color: theme("colors.cdlgrass.DEFAULT");
    height: 20px;
    width: 100vw;

    @media (min-width: @bp-md) {
      height: 60px;
    }

    @media (min-width: @bp-lg) {
      height: 100px;
    }
  }
}

@keyframes animateClouds {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1450px 0;
  }
}
</style>
